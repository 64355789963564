import React from 'react'
import { useTranslation } from 'react-i18next';

export default function Banner() {
    const { t } = useTranslation();
    return (
        <section class="bg-gray-50 py-12 lg:py-20 main-content">
            <div class="container mx-auto flex flex-col lg:flex-row items-center">
                <div class="w-full lg:w-1/2 px-4 mb-8 lg:mb-0 animate__animated animate__fadeInLeft order-2 lg:order-1">
                    <h1 class="mt-5 lg:mt-0 text-4xl lg:text-6sxl text-center lg:text-left font-bold text-gray-900 mb-4">{t("headerWelcomeText")}</h1>
                    <p class="text-gray-600 mb-6">
                        {t("headerWelcomeSubText")}
                    </p>
                    <div class="flex space-x-4">
                        <a href="#" class="flex bg-blue-500 text-white font-semibold py-3 px-7 rounded-full">
                            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                                <title>phone_call_line</title>
                                <g id="phone_call_line" fill='none' fill-rule='nonzero'>
                                    <path
                                        d='M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z' />
                                    <path fill='#FFFFFFFF'
                                        d='M6.857 2.445C8.12 3.366 9.076 4.66 9.89 5.849l.638.938A1.504 1.504 0 0 1 10.35 8.7l-1.356 1.356.143.304c.35.709.954 1.73 1.863 2.64.7.7 1.465 1.218 2.104 1.58l.367.197.327.162.146.067 1.355-1.356a1.502 1.502 0 0 1 1.918-.171l1.014.703c1.152.81 2.355 1.733 3.29 2.931a1.47 1.47 0 0 1 .189 1.485c-.837 1.953-2.955 3.616-5.158 3.534l-.3-.016-.233-.02-.258-.03-.281-.038-.305-.051-.326-.064-.346-.077-.366-.094-.385-.11-.402-.13c-1.846-.626-4.189-1.856-6.593-4.26-2.403-2.403-3.633-4.746-4.259-6.592l-.13-.402-.11-.385-.094-.366-.078-.346a11.79 11.79 0 0 1-.063-.326l-.05-.305-.04-.281-.029-.258-.02-.233-.016-.3c-.081-2.196 1.6-4.329 3.544-5.162a1.47 1.47 0 0 1 1.445.159ZM5.93 4.253c-1.072.56-2.11 1.84-2.063 3.121l.02.328.022.205.029.23.04.253.051.277.065.298.08.32.096.339.114.358c.042.122.086.247.134.375l.154.392.176.407c.628 1.382 1.652 3 3.325 4.672 1.672 1.672 3.29 2.697 4.672 3.325l.407.176.392.154c.128.048.253.092.375.134l.358.114.34.096.319.08.298.065.277.051.254.04.23.03.204.02.328.02c1.264.047 2.554-.985 3.112-2.043-.712-.835-1.596-1.52-2.571-2.21l-.748-.521-.19.199-.406.443-.215.226c-.586.597-1.27 1.104-2.09.773l-.226-.095-.276-.124-.154-.073-.338-.169-.371-.2a11.866 11.866 0 0 1-2.567-1.925 11.867 11.867 0 0 1-1.925-2.567l-.2-.37-.17-.339-.196-.43L7 10.48c-.311-.769.117-1.418.664-1.98l.224-.22.557-.513.2-.19-.473-.693c-.703-1.02-1.39-1.94-2.243-2.632Zm9.063 1.787.116.013a3.5 3.5 0 0 1 2.858 2.96 1 1 0 0 1-1.958.393l-.023-.115a1.5 1.5 0 0 0-1.07-1.233l-.155-.035a1 1 0 0 1 .232-1.983ZM15 3a6 6 0 0 1 6 6 1 1 0 0 1-1.993.117L19 9a3.998 3.998 0 0 0-3.738-3.991L15 5a1 1 0 1 1 0-2Z' />
                                </g>
                            </svg>
                            &nbsp; {t("contactUs")}
                        </a>
                        <a href="#" class="flex items-center text-gray-600 hover:text-gray-800">
                            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                                <title>user_4_line</title>
                                <g id="user_4_line" fill='none' fill-rule='evenodd'>
                                    <path
                                        d='M24 0v24H0V0h24ZM12.594 23.258l-.012.002-.071.035-.02.004-.014-.004-.071-.036c-.01-.003-.019 0-.024.006l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.016-.018Zm.264-.113-.014.002-.184.093-.01.01-.003.011.018.43.005.012.008.008.201.092c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.003-.011.018-.43-.003-.012-.01-.01-.184-.092Z' />
                                    <path fill='#09244BFF'
                                        d='M12 2c5.523 0 10 4.477 10 10a9.959 9.959 0 0 1-2.258 6.33l.02.022-.132.112A9.978 9.978 0 0 1 12 22c-2.95 0-5.6-1.277-7.43-3.307l-.2-.23-.132-.11.02-.024A9.958 9.958 0 0 1 2 12C2 6.477 6.477 2 12 2Zm0 15c-1.86 0-3.541.592-4.793 1.406A7.965 7.965 0 0 0 12 20a7.965 7.965 0 0 0 4.793-1.594A8.897 8.897 0 0 0 12 17Zm0-13a8 8 0 0 0-6.258 12.984C7.363 15.821 9.575 15 12 15s4.637.821 6.258 1.984A8 8 0 0 0 12 4Zm0 2a4 4 0 1 1 0 8 4 4 0 0 1 0-8Zm0 2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z' />
                                </g>
                            </svg>
                            &nbsp;{t("demo")}
                        </a>
                    </div>
                </div>
                <div class="w-full lg:w-1/2 px-4 animate__animated animate__fadeInRight order-1 lg:order-2">
                    <img src="assets/img/mockup.png" alt="Dashboard Screenshot" class="w-full" />
                </div>
            </div>
        </section>
    )
}
