import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';

const ImageSlider = () => {
    const [currentImages, setCurrentImages] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    const images = [
        { static: 'assets/img/iplik_planlama_mockup.png', gif: 'assets/img/iplik_planlama_mockup.gif' },
        { static: 'assets/img/iplik_siparis_mockup.png', gif: 'assets/img/iplik_siparis_mockup.gif' },
        { static: 'assets/img/iplik_sevk_mockup.png', gif: 'assets/img/iplik_sevk_mockup.gif' },
        { static: 'assets/img/recete_mockup.png', gif: 'assets/img/recete_mockup.gif' },
    ];

    useEffect(() => {
        setCurrentImages(images.map(img => img.static));
    }, []);

    const openModal = (img) => {
        setSelectedImage(img);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setSelectedImage('');
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 750,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    return (
        <div className="w-full outline-none">
            <Slider {...settings}>
                {currentImages.map((src, index) => (
                    <div key={index} onClick={() => openModal(src)}>
                        <img
                            src={src}
                            alt={`Image ${index + 1}`}
                            className="w-full rounded-xl cursor-pointer"
                        />
                    </div>
                ))}
            </Slider>
            {isOpen && (
                <div onClick={() => closeModal()} className="fixed inset-0 bg-white bg-opacity-80 flex items-center justify-center z-50">
                    <div className="relative">
                        <img
                            src={selectedImage}
                            alt="Selected"
                            className=""
                            style={{ width: "auto", height: 700, objectFit: 'contain'}}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default ImageSlider;
