import React from 'react'
import { useTranslation } from 'react-i18next';
import ImageSlider from '../../ImageSlider';

export default function YarnModule() {
    const { t } = useTranslation();
    return (
        <section class="bg-white py-12 px-4 lg:px-0">
            <div class=" mx-auto text-center">
                <h2 class="text-4xl font-semibold text-gray-900 mb-4">{t("yarnModule")}</h2>
                <p class="text-gray-600 xl:mb-12 mb-5">
                    {t("yarnModuleSub")}
                </p>
                <div class="flex flex-col xl:flex-row items-center justify-center xl:space-x-12">
                    <div class="w-full lg:hidden xl:block xl:w-1/3 mb-8 lg:mb-0">
                        <ImageSlider />
                    </div>
                    <div class="w-full xl:w-2/5 grid grid-cols-1 md:grid-cols-2 gap-6 xl:p-0 lg:p-6">
                        <div class="bg-gray-100 rounded-2xl p-6 text-left hover:shadow-inner cursor-pointer">
                            <div class="flex items-center mb-4">
                                <div class="bg-blue-500 text-white p-3 rounded-2xl">
                                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                                        <title>history_anticlockwise_line</title>
                                        <g id="history_anticlockwise_line" fill='none' fill-rule='nonzero'>
                                            <path
                                                d='M24 0v24H0V0h24ZM12.594 23.258l-.012.002-.071.035-.02.004-.014-.004-.071-.036c-.01-.003-.019 0-.024.006l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.016-.018Zm.264-.113-.014.002-.184.093-.01.01-.003.011.018.43.005.012.008.008.201.092c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.003-.011.018-.43-.003-.012-.01-.01-.184-.092Z' />
                                            <path fill='#FFFFFFFF'
                                                d='M20.66 7C23.422 11.783 21.783 17.9 17 20.66c-4.123 2.38-9.233 1.491-12.335-1.86a1 1 0 0 1 1.468-1.358 8 8 0 1 0-2.06-6.524l1.281-.335c1.047-.273 1.818.97 1.108 1.787L4.21 14.957c-.568.652-1.665.43-1.892-.444A10.003 10.003 0 0 1 7 3.34C11.783.579 17.899 2.217 20.66 7ZM12 6a1 1 0 0 1 1 1v4.586l2.707 2.707a1 1 0 1 1-1.414 1.414l-3-3A1 1 0 0 1 11 12V7a1 1 0 0 1 1-1Z' />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <h3 class="font-semibold text-xl text-gray-900 mb-3">{t("yarnPlanning")}</h3>
                            <p class="text-gray-600">
                                {t("yarnPlanningDesc")}
                            </p>
                        </div>
                        <div class="bg-gray-100 rounded-2xl p-6 text-left hover:shadow-inner cursor-pointer">
                            <div class="flex items-center mb-4">
                                <div class="bg-blue-500 text-white p-3 rounded-2xl">
                                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                                        <title>shopping_bag_3_line</title>
                                        <g id="shopping_bag_3_line" fill='none' fill-rule='nonzero'>
                                            <path
                                                d='M24 0v24H0V0zM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01z' />
                                            <path fill='#FFFFFFFF'
                                                d='M16.586 3A2 2 0 0 1 18 3.586L20.414 6A2 2 0 0 1 21 7.414V19a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V7.414A2 2 0 0 1 3.586 6L6 3.586A2 2 0 0 1 7.414 3zM19 9H5v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zm-4 2a1 1 0 0 1 1 1 4 4 0 0 1-8 0 1 1 0 1 1 2 0 2 2 0 0 0 3.995.15L14 12a1 1 0 0 1 1-1m1.586-6H7.414l-2 2h13.172z' />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <h3 class="font-semibold text-xl text-gray-900 mb-3">{t("yarnOrder")}</h3>
                            <p class="text-gray-600">
                                {t("yarnOrderDesc")}
                            </p>
                        </div>
                        <div class="bg-gray-100 rounded-2xl p-6 text-left hover:shadow-inner cursor-pointer">
                            <div class="flex items-center mb-4">
                                <div class="bg-blue-500 text-white p-3 rounded-2xl">
                                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                                        <title>truck_line</title>
                                        <g id="truck_line" fill='none' fill-rule='nonzero'>
                                            <path
                                                d='M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z' />
                                            <path fill='#FFFFFFFF'
                                                d='M15 4a2 2 0 0 1 2 2v1h1.52a2 2 0 0 1 1.561.75l1.48 1.851a2 2 0 0 1 .439 1.25V15a2 2 0 0 1-2 2 3 3 0 1 1-6 0h-4a3 3 0 1 1-6 0 2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h11ZM7 16a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm10 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM15 6H4v9h.764c.55-.614 1.348-1 2.236-1 .82 0 1.563.33 2.105.862l.131.138h5.528l.115-.121.121-.115V6Zm3.52 3H17v5c.82 0 1.563.33 2.105.862l.131.138H20v-4.15L18.52 9Z' />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <h3 class="font-semibold text-xl text-gray-900 mb-3">{t("yarnShipping")}</h3>
                            <p class="text-gray-600">
                                {t("yarnShippingDesc")}
                            </p>
                        </div>
                        <div class="bg-gray-100 rounded-2xl p-6 text-left hover:shadow-inner cursor-pointer">
                            <div class="flex items-center mb-4">
                                <div class="bg-blue-500 text-white p-3 rounded-2xl">
                                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                                        <title>document_2_line</title>
                                        <g id="document_2_line" fill='none' fill-rule='nonzero'>
                                            <path
                                                d='M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z' />
                                            <path fill='#FFFFFFFF'
                                                d='M13.586 2A2 2 0 0 1 15 2.586L19.414 7A2 2 0 0 1 20 8.414V20a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2ZM12 4H6v16h12V10h-4.5A1.5 1.5 0 0 1 12 8.5V4Zm3 10a1 1 0 1 1 0 2H9a1 1 0 1 1 0-2h6Zm-5-4a1 1 0 1 1 0 2H9a1 1 0 1 1 0-2Zm4-5.586V8h3.586L14 4.414Z' />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <h3 class="font-semibold text-xl text-gray-900 mb-3">{t("yarnProductReception")}</h3>
                            <p class="text-gray-600">
                                {t("yarnProductReceptionDesc")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
