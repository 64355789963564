import React from 'react'
import { useTranslation } from 'react-i18next';
import ImageSlider from '../../ImageSlider';
import ImageSliderMaintenance from '../../ImageSliderMaintenance';

export default function MachineMaintenance() {
    const { t } = useTranslation();
    return (
        <section class="bg-gray-50 py-12 px-4 lg:px-0">
            <div class=" mx-auto text-center">
                <h2 class="text-4xl font-semibold text-gray-900 mb-4">Makine Arıza Bakım</h2>
                <div class="flex flex-col xl:flex-row items-center justify-center xl:space-x-12">

                    <div class="w-full xl:w-2/5 grid grid-cols-1 md:grid-cols-2 gap-6 xl:p-0 lg:p-6">
                        <div class="bg-gray-100 rounded-2xl p-6 text-left hover:shadow-inner cursor-pointer">
                            <div class="flex items-center mb-4">
                                <div class="bg-blue-500 text-white p-3 rounded-2xl">
                                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><title>pdf_line</title><g id="pdf_line" fill='none' fill-rule='evenodd'><path d='M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z' /><path fill='#FFFFFFFF' d='M13.586 2a2 2 0 0 1 1.284.467l.13.119L19.414 7a2 2 0 0 1 .578 1.238l.008.176V20a2 2 0 0 1-1.85 1.995L18 22H6a2 2 0 0 1-1.995-1.85L4 20V4a2 2 0 0 1 1.85-1.995L6 2h7.586ZM12 4H6v16h12V10h-4.5a1.5 1.5 0 0 1-1.493-1.356L12 8.5V4Zm.988 7.848a6.223 6.223 0 0 0 2.235 3.872c.887.717.076 2.121-.988 1.712a6.223 6.223 0 0 0-4.47 0c-1.065.41-1.876-.995-.989-1.712a6.222 6.222 0 0 0 2.235-3.872c.178-1.127 1.8-1.126 1.977 0Zm-.99 2.304-.688 1.196h1.38l-.691-1.196ZM14 4.414V8h3.586L14 4.414Z' /></g></svg>
                                </div>
                            </div>
                            <h3 class="font-semibold text-xl text-gray-900 mb-3">{t("mainReport")}</h3>
                            <p class="text-gray-600">
                                {t("mainReportDesc")}
                            </p>
                        </div>
                        <div class="bg-gray-100 rounded-2xl p-6 text-left hover:shadow-inner cursor-pointer">
                            <div class="flex items-center mb-4">
                                <div class="bg-blue-500 text-white p-3 rounded-2xl">
                                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><title>qrcode_2_fill</title><g id="qrcode_2_fill" fill='none' fill-rule='nonzero'><path d='M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z' /><path fill='#FFFFFFFF' d='M11 3a2 2 0 0 1 1.995 1.85L13 5v6a2 2 0 0 1-1.85 1.995L11 13H5a2 2 0 0 1-1.995-1.85L3 11V5a2 2 0 0 1 1.85-1.995L5 3h6Zm0 2H5v6h6V5ZM9.5 6a.5.5 0 0 1 .492.41L10 6.5v3a.5.5 0 0 1-.41.492L9.5 10h-3a.5.5 0 0 1-.492-.41L6 9.5v-3a.5.5 0 0 1 .41-.492L6.5 6h3ZM21 5a2 2 0 0 0-2-2h-2a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2V5ZM3 17a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2Zm16-2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h2Zm-4-3a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm-2 4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0v-4Z' /></g></svg>
                                </div>
                            </div>
                            <h3 class="font-semibold text-xl text-gray-900 mb-3">{t("mainQr")}</h3>
                            <p class="text-gray-600">
                               {t("mainQrDesc")}
                            </p>
                        </div>
                        <div class="bg-gray-100 rounded-2xl p-6 text-left hover:shadow-inner cursor-pointer">
                            <div class="flex items-center mb-4">
                                <div class="bg-blue-500 text-white p-3 rounded-2xl">
                                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><title>report_line</title><g id="report_line" fill='none' fill-rule='nonzero'><path d='M24 0v24H0V0h24ZM12.594 23.258l-.012.002-.071.035-.02.004-.014-.004-.071-.036c-.01-.003-.019 0-.024.006l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.016-.018Zm.264-.113-.014.002-.184.093-.01.01-.003.011.018.43.005.012.008.008.201.092c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.003-.011.018-.43-.003-.012-.01-.01-.184-.092Z' /><path fill='#FFFFFFFF' d='M12 7a6 6 0 0 1 5.996 5.775L18 13v7h1a1 1 0 0 1 .117 1.993L19 22H5a1 1 0 0 1-.117-1.993L5 20h1v-7a6 6 0 0 1 6-6Zm0 2a4 4 0 0 0-4 4v7h8v-7a4 4 0 0 0-4-4Zm.515 2.643a1 1 0 0 1 .342 1.371l-.59.986h1.215c.785 0 1.27.857.866 1.53l-1.49 2.485a1 1 0 0 1-1.716-1.03l.592-.985h-1.216a1.01 1.01 0 0 1-.866-1.53l1.49-2.485a1 1 0 0 1 1.373-.342ZM5.636 5.222l.707.707A1 1 0 0 1 4.93 7.343l-.707-.707a1 1 0 1 1 1.414-1.414Zm14.142 0a1 1 0 0 1 .083 1.32l-.083.094-.707.707a1 1 0 0 1-1.497-1.32l.083-.094.707-.707a1 1 0 0 1 1.414 0ZM12 2a1 1 0 0 1 .993.883L13 3v1a1 1 0 0 1-1.993.117L11 4V3a1 1 0 0 1 1-1Z' /></g></svg>
                                </div>
                            </div>
                            <h3 class="font-semibold text-xl text-gray-900 mb-3">{t("mainPeriodic")}</h3>
                            <p class="text-gray-600">
                                {t("mainPeriodicDesc")}
                            </p>
                        </div>
                        <div class="bg-gray-100 rounded-2xl p-6 text-left hover:shadow-inner cursor-pointer">
                            <div class="flex items-center mb-4">
                                <div class="bg-blue-500 text-white p-3 rounded-2xl">
                                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><title>report_line</title><g id="report_line" fill='none' fill-rule='nonzero'><path d='M24 0v24H0V0h24ZM12.594 23.258l-.012.002-.071.035-.02.004-.014-.004-.071-.036c-.01-.003-.019 0-.024.006l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.016-.018Zm.264-.113-.014.002-.184.093-.01.01-.003.011.018.43.005.012.008.008.201.092c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.003-.011.018-.43-.003-.012-.01-.01-.184-.092Z' /><path fill='#FFFFFFFF' d='M12 7a6 6 0 0 1 5.996 5.775L18 13v7h1a1 1 0 0 1 .117 1.993L19 22H5a1 1 0 0 1-.117-1.993L5 20h1v-7a6 6 0 0 1 6-6Zm0 2a4 4 0 0 0-4 4v7h8v-7a4 4 0 0 0-4-4Zm.515 2.643a1 1 0 0 1 .342 1.371l-.59.986h1.215c.785 0 1.27.857.866 1.53l-1.49 2.485a1 1 0 0 1-1.716-1.03l.592-.985h-1.216a1.01 1.01 0 0 1-.866-1.53l1.49-2.485a1 1 0 0 1 1.373-.342ZM5.636 5.222l.707.707A1 1 0 0 1 4.93 7.343l-.707-.707a1 1 0 1 1 1.414-1.414Zm14.142 0a1 1 0 0 1 .083 1.32l-.083.094-.707.707a1 1 0 0 1-1.497-1.32l.083-.094.707-.707a1 1 0 0 1 1.414 0ZM12 2a1 1 0 0 1 .993.883L13 3v1a1 1 0 0 1-1.993.117L11 4V3a1 1 0 0 1 1-1Z' /></g></svg>
                                </div>
                            </div>
                            <h3 class="font-semibold text-xl text-gray-900 mb-3">{t("mainPlanned")}</h3>
                            <p class="text-gray-600">
                               {t("mainPlannedDesc")}
                            </p>
                        </div>
                    </div>
                    <div class="w-full lg:hidden xl:block xl:w-1/3 mb-8 lg:mb-0">
                        <ImageSliderMaintenance />
                    </div>
                </div>
            </div>
        </section>
    )
}
