import React from 'react'
import { useTranslation } from 'react-i18next';

export default function Faq() {

    const { t } = useTranslation();

    return (
        <section id="faq" class="bg-white py-4 lg:py-12 mb-5 px-4 lg:px-0">
            <div class="container mx-auto text-center">
                <h2 class="text-5xl font-semibold text-gray-900 mb-16">{t("navFaqs")}</h2>
                <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 lg:grid-cols-3 gap-6">
                    <div class="bg-gray-50 p-6 rounded-xl">
                        <div class="flex items-center justify-center mb-4">
                            <div class="bg-blue-500 text-white p-3 rounded-full">
                                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M12 14l9-5-9-5-9 5 9 5zm0 0v6m9-11v6a9 9 0 11-18 0V9"></path>
                                </svg>
                            </div>
                        </div>
                        <h3 class="text-xl font-semibold text-gray-900 mb-2">{t("faq.one.question")}</h3>
                        <p class="text-gray-600">{t("faq.one.answer")}</p>
                    </div>
                    <div class="bg-gray-50 p-6 rounded-xl">
                        <div class="flex items-center justify-center mb-4">
                            <div class="bg-blue-500 text-white p-3 rounded-full">
                                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M12 14l9-5-9-5-9 5 9 5zm0 0v6m9-11v6a9 9 0 11-18 0V9"></path>
                                </svg>
                            </div>
                        </div>
                        <h3 class="text-xl font-semibold text-gray-900 mb-2">{t("faq.two.question")}</h3>
                        <p class="text-gray-600">{t("faq.two.answer")}</p>
                    </div>
                    <div class="bg-gray-50 p-6 rounded-xl">
                        <div class="flex items-center justify-center mb-4">
                            <div class="bg-blue-500 text-white p-3 rounded-full">
                                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M12 14l9-5-9-5-9 5 9 5zm0 0v6m9-11v6a9 9 0 11-18 0V9"></path>
                                </svg>
                            </div>
                        </div>
                        <h3 class="text-xl font-semibold text-gray-900 mb-2">{t("faq.three.question")}</h3>
                        <p class="text-gray-600">{t("faq.three.answer")}</p>
                    </div>
                    <div class="bg-gray-50 p-6 rounded-xl">
                        <div class="flex items-center justify-center mb-4">
                            <div class="bg-blue-500 text-white p-3 rounded-full">
                                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M12 14l9-5-9-5-9 5 9 5zm0 0v6m9-11v6a9 9 0 11-18 0V9"></path>
                                </svg>
                            </div>
                        </div>
                        <h3 class="text-xl font-semibold text-gray-900 mb-2">{t("faq.four.question")}</h3>
                        <p class="text-gray-600">{t("faq.four.answer")}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}
