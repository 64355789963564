import React from 'react'
import { useTranslation } from 'react-i18next'

export default function PurchaseOptions() {

    const { t } = useTranslation();

    return (
        <section id="pricing" class="bg-gray-50 px-4 lg:px-0 py-12 mx-auto">
            <div class="container mx-auto text-center w-full lg:w-3/5 w-mac-3-5">
                <h1 class="text-5xl font-bold text-gray-900 mb-8">Satın Alma Seçenekleri</h1>
                <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div class="bg-white border border-gray-100 rounded-lg p-6">
                        <h3 class="text-xl font-semibold text-blue-500 mb-4">Sunucunuzda Kiralık</h3>
                        <p class="text-gray-500 mb-4 flex justify-center items-center"><svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 24 24'><title>information_line</title><g id="information_line" fill='none' fill-rule='nonzero'><path d='M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z' /><path fill='#09244BFF' d='M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2Zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16Zm-.01 6c.558 0 1.01.452 1.01 1.01v5.124A1 1 0 0 1 12.5 18h-.49A1.01 1.01 0 0 1 11 16.99V12a1 1 0 1 1 0-2h.99ZM12 7a1 1 0 1 1 0 2 1 1 0 0 1 0-2Z' /></g></svg>&nbsp;Bilgilendirme</p>
                        <ul class="text-left mb-6 space-y-2">
                            <li class="flex items-center text-gray-700">
                                <svg class="w-6 h-6 text-blue-500 mr-2" fill="none" stroke="currentColor"
                                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M5 13l4 4L19 7"></path>
                                </svg>
                                ERP sistemimizi kendi sunucularınızda kiralayabilirsiniz.
                            </li>
                            <li class="flex items-center text-gray-700">
                                <svg class="w-6 h-6 text-blue-500 mr-2" fill="none" stroke="currentColor"
                                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M5 13l4 4L19 7"></path>
                                </svg>
                                Bu seçenekle tüm yazılım ve veriler, sizin altyapınızda barındırılacaktır.
                            </li>
                            <li class="flex items-center text-gray-700">
                                <svg class="w-6 h-6 text-blue-500 mr-2" fill="none" stroke="currentColor"
                                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M5 13l4 4L19 7"></path>
                                </svg>
                                Sorumluluk: Sistem güvenliği sizin sorumluluğunuzdadır.
                            </li>
                        </ul>
                    </div>

                    <div class="bg-white border border-gray-100 rounded-lg p-6 ">
                        <h3 class="text-xl font-semibold text-blue-500 mb-4">Sunucunuzda Satılık</h3>
                        <p class="text-gray-500 mb-4 flex justify-center items-center"><svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 24 24'><title>information_line</title><g id="information_line" fill='none' fill-rule='nonzero'><path d='M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z' /><path fill='#09244BFF' d='M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2Zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16Zm-.01 6c.558 0 1.01.452 1.01 1.01v5.124A1 1 0 0 1 12.5 18h-.49A1.01 1.01 0 0 1 11 16.99V12a1 1 0 1 1 0-2h.99ZM12 7a1 1 0 1 1 0 2 1 1 0 0 1 0-2Z' /></g></svg>&nbsp;Bilgilendirme</p>
                        <ul class="text-left mb-6 space-y-2">
                            <li class="flex items-center text-gray-700">
                                <svg class="w-6 h-6 text-blue-500 mr-2" fill="none" stroke="currentColor"
                                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M5 13l4 4L19 7"></path>
                                </svg>
                                ERP sistemimizi tek seferlik ödeme ile kendi sunucularınıza kurabilirsiniz.
                            </li>
                            <li class="flex items-center text-gray-700">
                                <svg class="w-6 h-6 text-blue-500 mr-2" fill="none" stroke="currentColor"
                                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M5 13l4 4L19 7"></path>
                                </svg>
                                Bu seçenekle tüm yazılım ve veriler, sizin altyapınızda barındırılacaktır.
                            </li>
                            <li class="flex items-center text-gray-700">
                                <svg class="w-6 h-6 text-blue-500 mr-2" fill="none" stroke="currentColor"
                                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M5 13l4 4L19 7"></path>
                                </svg>
                                Sorumluluk: Sistem güvenliği sizin sorumluluğunuzdadır.
                            </li>
                        </ul>
                    </div>

                    <div class="bg-white border border-gray-100 rounded-lg p-6">
                        <h3 class="text-xl font-semibold text-blue-500 mb-4">Bulutta Kiralık</h3>
                        <p class="text-gray-500 mb-4 flex justify-center items-center"><svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 24 24'><title>information_line</title><g id="information_line" fill='none' fill-rule='nonzero'><path d='M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z' /><path fill='#09244BFF' d='M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2Zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16Zm-.01 6c.558 0 1.01.452 1.01 1.01v5.124A1 1 0 0 1 12.5 18h-.49A1.01 1.01 0 0 1 11 16.99V12a1 1 0 1 1 0-2h.99ZM12 7a1 1 0 1 1 0 2 1 1 0 0 1 0-2Z' /></g></svg>&nbsp;Bilgilendirme</p>
                        <ul class="text-left mb-6 space-y-2">
                            <li class="flex items-center text-gray-700">
                                <svg class="w-6 h-6 text-blue-500 mr-2" fill="none" stroke="currentColor"
                                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M5 13l4 4L19 7"></path>
                                </svg>
                                ERP sistemimizi bulut altyapımızda kiralayabilirsiniz.
                            </li>
                            <li class="flex items-center text-gray-700">
                                <svg class="w-6 h-6 text-blue-500 mr-2" fill="none" stroke="currentColor"
                                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M5 13l4 4L19 7"></path>
                                </svg>
                                Bu seçenekle tüm yazılım ve veriler, güvenli ve yüksek performanslı bulut sunucularımızda barındırılacaktır.
                            </li>
                            <li class="flex items-center text-gray-700">
                                <svg class="w-6 h-6 text-blue-500 mr-2" fill="none" stroke="currentColor"
                                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M5 13l4 4L19 7"></path>
                                </svg>
                                Sorumluluk: Sistem yönetimi, bakım ve güvenlik bizim sorumluluğumuzdadır.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}
