import React from 'react'
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';

export default function Stats() {
    const { t } = useTranslation();
    return (
        <section class="bg-white py-16">
            <div class="container mx-auto text-center">
                <div class="flex flex-col md:flex-row justify-center items-center space-y-6 md:space-y-0 md:space-x-12">
                    <div>
                        <h3 id="projects" class="text-3xl font-bold text-gray-900">
                            <CountUp end={340} duration={5} />
                        </h3>
                        <p class="text-gray-600">Successful Projects</p>
                    </div>
                    <div>
                        <h3 id="rate" class="text-3xl font-bold text-gray-900">
                            <CountUp end={2840} duration={5} />
                        </h3>
                        <p class="text-gray-600">Annual Percentage Rate</p>
                    </div>
                    <div>
                        <h3 id="clients" class="text-3xl font-bold text-gray-900">
                            <CountUp end={35} duration={5} />
                        </h3>
                        <p class="text-gray-600">Clients Worldwide</p>
                    </div>
                    <div>
                        <h3 id="visits" class="text-3xl font-bold text-gray-900">
                            <CountUp end={34005} duration={5} />
                        </h3>
                        <p class="text-gray-600">Daily Visits</p>
                    </div>
                </div>
            </div>
        </section>
    )
}
