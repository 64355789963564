import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "navHome": "Home",
      "navAbout": "About",
      "navServices": "Our Services",
      "navFaqs": "FAQs",
      "navPricing": "Pricing",
      "navContact": "Contact Us",
      "goPanel": "Go to Panel",
      "headerWelcomeText": "Make your production processes a success with ilgiERP.",
      "headerWelcomeSubText": "Take advantage of the comprehensive solutions offered by ilgiERP to increase your production efficiency, reduce your costs and optimize your operational processes in the textile industry.\n\nTake control at all stages from raw material procurement to final product delivery with our powerful planning and management tools. With its flexible and user-friendly interface, ilgiERP adds value to your business and provides a competitive advantage in the industry. Perfect your production processes and achieve success with our modules designed for businesses of all sizes.",
      "contactUs": "Contact Us",
      "demo": "Request a Demo",
      "link": "Links",
      "contactInfo": "Contact Information",
      "other": "Others",
      "footerDesc": "As İlgisoft, we have been with you since 2006 to digitize your processes by developing end-to-end solutions.",
      "yarnModule": "Yarn Module",
      "yarnModuleSub": "The Yarn Module is an ERP system feature that enables the management of yarn inventory in textile production processes.",
      "yarnPlanning": "Yarn Planning",
      "yarnOrder": "Yarn Order",
      "yarnShipping": "Yarn Shipping",
      "yarnProductReception": "Yarn Product Reception",
      "yarnPlanningDesc": "By providing effective planning and management at every stage of the production line ensures efficient use of resources and timely realization of production targets.",
      "yarnOrderDesc": "It aims to meet customer demands accurately and on time, to carry out order processes smoothly and to increase customer satisfaction.",
      "yarnShippingDesc": "It aims to ensure that products are delivered to customers on time and accurately, and that shipment processes are carried out, tracked and reported smoothly.",
      "yarnProductReceptionDesc": "It provides detailed planning of raw materials, machines and sequences required in production. Easy to use and effective, this module aims to ensure that production processes proceed flawlessly.",
      "pricingDesc": "ilgiERP pricing table is given below.",
      "faq": {
        "one": {
          "question": "What is the ilgiERP system?",
          "answer": "It is an ERP software specially developed for the textile industry. This system increases the efficiency of businesses by integrating all production processes from raw materials to the final product."
        },
        "two": {
          "question": "Which businesses is ilgiERP suitable for?",
          "answer": "It is suitable for textile businesses of all sizes, helping small, medium or large enterprises to manage their operations effectively.",
        },
        "three": {
          "question": "What specific advantages does ilgiERP provide to the textile industry?",
          "answer": "Industry-specific modules such as production planning, quality control, yarn and fabric management reduce costs and increase operational efficiency.",
        },
        "four": {
          "question": "Can I get technical support when using ilgiERP?",
          "answer": "Yes, ilgiERP offers its users comprehensive technical support for technical issues, customizations and training needs."
        }
      },
      "contactForm": {
        "name": "Name",
        "surname": "Surname",
        "email": "E-mail Address",
        "phone": "Phone Number",
        "subject": "Subject",
        "message": "Message",
      },
      "footerCopyright": "All rights reserved.",
      "kvkk": "GDPR Information",
      "privacy": "Privacy Policy",
      "useage": "Terms of Use",
      "footerAddress": "Çorlu Engineering Faculty Teknokent No: 12 Çorlu/Tekirdağ",
      "mainReport":"Maintenance Report",
      "mainReportDesc":"It provides fast and detailed reporting of malfunctions that occur in machines. Users can enter information about the type of malfunction, cause and solution process, helping maintenance teams to intervene faster.",
      "mainQr": "Fault Entry with QR",
      "mainQrDesc": "It enables the use of QR codes for easy reporting of faults. By scanning the QR code on the machine, maintenance personnel can quickly enter the fault record into the system and initiate the necessary maintenance operations.",
      "mainPeriodic": "Periodic Maintenance",
      "mainPeriodicDesc": "Plans and monitors maintenance at regular intervals. This feature ensures continuity of maintenance, extending machine life and preventing sudden breakdowns.",
      "mainPlanned": "Planned Maintenance",
      "mainPlannedDesc": "It enables the realization of pre-planned maintenance activities according to a specific schedule. In this way, businesses organize maintenance operations and minimize production interruptions."
    },
  },
  tr: {
    translation: {
      "navHome": "Anasayfa",
      "navAbout": "Hakkımızda",
      "navServices": "Hizmetlerimiz",
      "navFaqs": "Sıkça Sorulan Sorular",
      "navPricing": "Satın Alma Seçenekleri",
      "navContact": "İletişime Geç",
      "goPanel": "Panele Giriş Yap",
      "headerWelcomeText": "ilgiERP ile üretim süreçlerinizi başarıya ulaştırın.",
      "headerWelcomeSubText": "Tekstil endüstrisinde üretim verimliliğinizi artırmak, maliyetlerinizi azaltmak ve operasyonel süreçlerinizi optimize etmek için ilgiERP'nin sunduğu kapsamlı çözümlerden yararlanın.\n\nGüçlü planlama ve yönetim araçlarımızla, hammadde tedarikinden nihai ürün teslimatına kadar tüm aşamalarda kontrolü ele alın. ilgiERP, esnek ve kullanıcı dostu arayüzüyle işletmenize değer katarken, sektörde rekabet avantajı sağlar. Her ölçekten işletme için tasarlanmış modüllerimizle, üretim süreçlerinizi mükemmelleştirin ve başarıya ulaşın.",
      "contactUs": "İletişime Geç",
      "demo": "Demo Hesap Giriş Yap",
      "link": "Bağlantılar",
      "contactInfo": "İletişim Bilgilerimiz",
      "other": "Diğer",
      "footerDesc": "İlgisoft olarak, uçtan uca çözümler geliştirerek süreçlerinizi dijital ortama taşımak için 2006 dan beri yanınızdayız.",
      "yarnModule": "İplik Modülü",
      "yarnModuleSub": "İplik Modülü tekstil üretim süreçlerinde iplik envanterinin yönetilmesini sağlayan bir ERP sistemi özelliğidir.",
      "yarnPlanning": "İplik Planlama",
      "yarnOrder": "İplik Siparişleri",
      "yarnShipping": "İplik Sevkiyat",
      "yarnProductReception": "Üretim Reçeteleri",
      "yarnPlanningDesc": "Üretim hattının her aşamasında etkin planlama ve yönetim sağlayarak kaynakların verimli kullanımını ve üretim hedeflerinin zamanında gerçekleştirilmesini sağlar.",
      "yarnOrderDesc": "Müşteri taleplerinin doğru ve zamanında karşılanmasını, sipariş süreçlerinin sorunsuz bir şekilde yürütülmesini ve müşteri memnuniyetinin artırılmasını hedefler.",
      "yarnShippingDesc": "Ürünlerin zamanında ve doğru bir şekilde müşterilere ulaştırılmasını, sevkiyat süreçlerinin sorunsuz bir şekilde yürütülmesini, takip edilmesini ve raporlanmasını hedefler.",
      "yarnProductReceptionDesc": "Üretimde gerekli olan hammadde, makine ve dizilimlerin detaylı olarak planlanmasını sağlar. Kullanımı kolay ve etkili olan bu modülde üretim süreçlerinin kusursuz bir şekilde ilerlemesi hedeflenmiştir.",
      "pricingDesc": "ilgiERP ücretlendirme tablosu aşağıda yer almaktadır.",
      "faq": {
        "one": {
          "question": "ilgiERP sistemi nedir?",
          "answer": "Tekstil sektörüne yönelik özel olarak geliştirilmiş bir ERP yazılımıdır. Bu sistem, hammaddeden nihai ürüne kadar tüm üretim süreçlerini entegre ederek işletmelerin verimliliğini artırır."
        },
        "two": {
          "question": "ilgiERP hangi işletmeler için uygundur?",
          "answer": "Tekstil üzerine çalışan her ölçekten işletme için uygundur; küçük, orta veya büyük ölçekli işletmelerin operasyonlarını etkin bir şekilde yönetmesine yardımcı olur.",
        },
        "three": {
          "question": "ilgiERP tekstil sektörüne hangi özel avantajları sağlar?",
          "answer": "Üretim planlaması, kalite kontrol, iplik ve kumaş yönetimi gibi sektöre özgü modüllerle maliyetleri düşürür ve operasyonel verimliliği artırır.",
        },
        "four": {
          "question": "ilgiERP kullanırken teknik destek alabilir miyim?",
          "answer": "Evet, ilgiERP kullanıcılarına teknik sorunlar, özelleştirmeler ve eğitim ihtiyaçları için kapsamlı teknik destek sunmaktadır."
        }
      },
      "contactForm": {
        "name": "Adınız",
        "surname": "Soyadınız",
        "email": "E-posta Adresiniz",
        "phone": "Telefon Numaranız",
        "subject": "Konu",
        "message": "Mesajınız",
      },
      "footerCopyright": "Tüm hakları saklıdır.",
      "kvkk": "KVKK Aydınlatma Metni",
      "privacy": "Gizlilik Politikası",
      "useage": "Kullanım Koşulları",
      "footerAddress": "Çorlu Mühendislik Fakültesi Teknokent No: 12 Çorlu/Tekirdağ",
      "mainReport":"Arıza Raporu",
      "mainReportDesc":"Makinelerde meydana gelen arızaların hızlı ve detaylı bir şekilde raporlanmasını sağlar.Kullanıcılar, arıza türü, nedeni ve çözüm süreci hakkında bilgi girerek, bakım ekiplerinin daha hızlı müdahale etmesine yardımcı olur.",
      "mainQr": "QR ile Arıza Giriş",
      "mainQrDesc": "Arızaların kolayca bildirilmesi için QR kodlarının kullanılmasını sağlar. Bakım personeli, makine üzerindeki QR kodunu tarayarak arıza kaydını hızla sisteme girebilir ve gerekli bakım işlemlerinin başlatılmasını sağlayabilir.",
      "mainPeriodic": "Periyodik Bakım",
      "mainPeriodicDesc": "Düzenli aralıklarla bakım yapılmasını planlar ve takip eder. Bu özellik, bakım sürekliliğini sağlayarak makine ömrünü uzatır ve ani arızaların önüne geçer.",
      "mainPlanned": "Planlı Bakım",
      "mainPlannedDesc": "Belirli bir takvime göre önceden planlanmış bakım faaliyetlerinin gerçekleştirilmesini sağlar. Bu sayede, işletmeler bakım işlemlerini organize eder ve üretim kesintilerini en aza indirir."
    },
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'tr', // default language
    fallbackLng: 'tr', // fallback language if not available

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;