import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../LanguageSelector';

export default function Header() {

    const { t } = useTranslation();

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <header className="bg-white fixed top-0 left-0 w-full z-50 backdrop-blur bg-opacity-70">
            <div className="header-container container mx-auto px-4 mt-2 py-2 flex lg:justify-center xl:space-x-0 space-x-8 xl:justify-between items-center">
                <div className="flex items-center space-x-2">
                    <img src="assets/img/logo.svg" alt="ilgiERP Logo" className="h-14" />
                </div>
                <nav className="hidden md:flex space-x-10 text-gray-600">
                    <a href="index.html" className="hover:text-gray-800">{t("navHome")}</a>
                    <a href="#faq" className="hover:text-gray-800">{t("navServices")}</a>
                    <a href="#faq" className="hover:text-gray-800">{t("navFaqs")}</a>
                    <a href="#pricing" className="hover:text-gray-800">{t("navPricing")}</a>
                    <a href="#contact" className="hover:text-gray-800">{t("navContact")}</a>
                </nav>
                <div className="hidden t md:flex items-center space-x-4">
                    <a href="https://panel.ilgierp.com" target="_blank"
                        className="hidden t md:flex bg-gray-100 text-blue-600 font-semibold py-2.5 text-sm px-7 rounded-lg">
                        {t("goPanel")}
                    </a>
                    <LanguageSelector />
                </div>
                {/* Mobile menu button */}
                <button onClick={toggleMobileMenu} className="border border-gray-200 p-3 rounded-xl md:hidden focus:outline-none">
                    <svg className="w-6 h-6 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                    </svg>
                </button>
            </div>
            {/* Mobile menu */}
            {isMobileMenuOpen && (
                <div className="md:hidden">
                    <nav className="px-2 pt-2 pb-4 space-y-1">
                        <a href="/" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-800">{t("navHome")}</a>
                        <a href="#" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-800">{t("navAbout")}</a>
                        <a href="#services" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-800">{t("navServices")}</a>
                        <a href="#faq" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-800">{t("navFaqs")}</a>
                        <a href="#pricing" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-800">{t("navPricing")}</a>
                        <a href="#contact" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-800">{t("navContact")}</a>
                        <LanguageSelector />
                    </nav>
                </div>
            )}
        </header>
    )
}
