import React from 'react'
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import ContactUs from './components/common/ContactUs';
import PricingTable from './components/common/PricingTable';
import Faq from './components/common/Faq';
import Services from './components/common/Services';
import Banner from './components/common/Banner';
import Stats from './components/common/Stats';
import YarnModule from './components/common/modules/YarnModule';
import PurchaseOptions from './components/common/PurchaseOptions';
import MachineMaintenance from './components/common/modules/MachineMaintenance';

export default function App() {
  return (
    <>
      <Header />
      <Banner />
      <Stats />
      <YarnModule />
      <MachineMaintenance />
      <Faq />
      <PurchaseOptions />
      <ContactUs />
      <Footer />
    </>
  )
}
