import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ContactUs() {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('http://api.ilgisoft.com/api/IlgiMail/SendMail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                alert('Message sent successfully!');
            } else {
                alert('Failed to send message.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred.');
        }
        window.location.reload();
    };

    return (
        <section id="contact" className="bg-white py-6 lg:py-8 xl:py-12">
            <div className="flex justify-center lg:mt-0 mt-3 mb-0 xl:mb-6">
                <h2 className="text-4xl font-semibold text-gray-900 mb-8">{t("navContact")}</h2>
            </div>
            <div className="container text-center mx-auto px-4 flex flex-col lg:px-8 xl:flex-row items-center xl:space-x-12">
                <div className="w-full mb-5 lg:mb-0 lg:w-full">
                    <div className="w-full rounded-lg overflow-hidden">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6005.689982762865!2d27.817149!3d41.181548!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x63b90eb3ed180cd3!2silgisoft%20Bili%C5%9Fim%20ve%20Dan%C4%B1%C5%9Fmanl%C4%B1k%20Hizmetleri!5e0!3m2!1str!2str!4v1653901553783!5m2!1str!2str"
                            width="100%" className="map" allowFullScreen="" loading="lazy"></iframe>
                    </div>
                </div>
                <div className="w-full xl:w-1/2 border border-gray-100 p-6 rounded-xl mb-12 lg:mb-0 text-left">
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                            <div>
                                <label htmlFor="name" className="block text-gray-700 font-bold text-sm">{t("contactForm.name")}:</label>
                                <input type="text" id="name" name="name"
                                    className="w-full mt-1 px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500" 
                                    value={formData.name} onChange={handleChange} required />
                            </div>
                            <div>
                                <label htmlFor="surname" className="block text-gray-700 font-bold text-sm">{t("contactForm.surname")}:</label>
                                <input type="text" id="surname" name="surname"
                                    className="w-full mt-1 px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500" 
                                    value={formData.surname} onChange={handleChange} required />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="email" className="block text-gray-700 font-bold text-sm">{t("contactForm.email")}:</label>
                            <input type="email" id="email" name="email"
                                className="w-full mt-1 px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500" 
                                value={formData.email} onChange={handleChange} required />
                        </div>
                        <div>
                            <label htmlFor="phone" className="block text-gray-700 font-bold text-sm">{t("contactForm.phone")}:</label>
                            <input type="tel" id="phone" name="phone"
                                className="w-full mt-1 px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500" 
                                value={formData.phone} onChange={handleChange} required />
                        </div>
                        <div>
                            <label htmlFor="subject" className="block text-gray-700 font-bold text-sm">{t("contactForm.subject")}:</label>
                            <input type="text" id="subject" name="subject"
                                className="w-full mt-1 px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500" 
                                value={formData.subject} onChange={handleChange} required />
                        </div>
                        <div>
                            <label htmlFor="message" className="block text-gray-700 font-bold text-sm">{t("contactForm.message")}:</label>
                            <textarea id="message" name="message" rows="5"
                                className="w-full mt-1 px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                                value={formData.message} onChange={handleChange} required></textarea>
                        </div>
                        <div className="flex justify-end">
                            <button type="submit"
                                className="w-full justify-center bg-blue-500 text-white font-semibold py-3 px-6 rounded-lg focus:ring-opacity-50 flex">
                                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                                    <title>send_plane_line</title>
                                    <g id="send_plane_line" fill='none' fillRule='nonzero'>
                                        <path
                                            d='M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z' />
                                        <path fill='#FFFFFFFF'
                                            d='M20.25 3.532a1 1 0 0 1 1.183 1.329l-6 15.5a1 1 0 0 1-1.624.362l-3.382-3.235-1.203 1.202c-.636.636-1.724.186-1.724-.714v-3.288L2.309 9.723a1 1 0 0 1 .442-1.691l17.5-4.5Zm-2.114 4.305-7.998 6.607 3.97 3.798 4.028-10.405Zm-1.578-1.29L4.991 9.52l3.692 3.53 7.875-6.505Z' />
                                    </g>
                                </svg>
                                &nbsp; {t("navContact")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}
