import React from 'react'
import { useTranslation } from 'react-i18next'

export default function Footer() {

    const { t } = useTranslation();

    return (
        <footer class="bg-gray-50 py-8">
            <div class="container mx-auto">
                <div
                    class="mb-12 space-x-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 text-gray-500 font-medium">
                    <div className='lg:text-left text-center lg:px-0 px-4'>
                        <img src="assets/img/logo.svg" alt="Logo" class="w-32 mx-auto mb-4" />
                        <span>
                            {t("footerDesc")}
                        </span>
                    </div>
                    <div>
                        <h4 class="text-blue-900 font-bold mb-2">{t("link")}</h4>
                        <ul class="space-y-3">
                            <li><a href="#" class="hover:underline">{t("navHome")}</a></li>
                            <li><a href="#" class="hover:underline">{t("navAbout")}</a></li>
                            <li><a href="#" class="hover:underline">{t("navServices")}</a></li>
                            <li><a href="#" class="hover:underline">{t("navPricing")}</a></li>
                            <li><a href="#" class="hover:underline">{t("navContact")}</a></li>
                        </ul>
                    </div>
                    <div>
                        <h4 class="text-blue-900 font-bold mb-2">{t("contactInfo")}</h4>
                        <ul class="space-y-3">
                            <li>
                                <a href="#" class="flex">
                                    Email: info@ilgisoft.com
                                </a>
                            </li>
                            <li><a href="#" class="hover:underline">
                                Telefon: +90 538 279 95 13
                            </a></li>
                            <li><a href="#" class="hover:underline">
                                <p>
                                    {t("footerAddress")}
                                </p>
                            </a></li>
                        </ul>
                    </div>
                    <div>
                        <h4 class="text-blue-900 font-bold mb-2">{t("other")}</h4>
                        <ul class="space-y-3">
                            <li><a href="#" class="hover:underline">{t("kvkk")}</a></li>
                            <li><a href="#" class="hover:underline">{t("useage")}</a></li>
                            <li><a href="#" class="hover:underline">{t("privacy")}</a></li>
                            <li><a href="https://panel.ilgierp.com" class="hover:underline">{t("goPanel")}</a></li>
                        </ul>
                    </div>
                </div>
                <div class="mb-6 flex justify-center space-x-6">
                    <a href="#"><i class="text-gray-500 text-xl fab fa-facebook-f"></i></a>
                    <a href="#"><i class="text-gray-500 text-xl fab fa-twitter"></i></a>
                    <a href="#"><i class="text-gray-500 text-xl fab fa-github"></i></a>
                    <a href="#"><i class="text-gray-500 text-xl fab fa-instagram"></i></a>
                    <a href="#"><i class="text-gray-500 text-xl fab fa-linkedin"></i></a>
                </div>
                <div class="text-gray-500 flex justify-center">
                    © 2024, ilgiERP. {t("footerCopyright")}
                </div>
            </div>
        </footer>
    )
}
