import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export default function LanguageSelector() {
    const [isOpen, setIsOpen] = useState(false);
    const [currentLang, setCurrentLang] = useState({
        code: 'TR',
        name: 'TR',
        flag: 'assets/img/tr.png'
    });
    
    const { i18n } = useTranslation();
    const dropdownRef = useRef(null);  // Ref to the wrapper of dropdown

    const languages = [
        { code: 'EN', name: 'English', flag: 'assets/img/en.png' },
        { code: 'TR', name: 'Türkçe', flag: 'assets/img/tr.png' }
    ];

    useEffect(() => {
        const storedLang = localStorage.getItem('lang');
        if (storedLang) {
            const langData = JSON.parse(storedLang);
            setCurrentLang(langData);
            i18n.changeLanguage(langData.code.toLowerCase());
        } else {
            i18n.changeLanguage(currentLang.code.toLowerCase());
        }
    }, [i18n, currentLang.code]);

    useEffect(() => {
        // Function to detect click outside the dropdown
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);  // Close dropdown if clicked outside
            }
        };

        // Adding click event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Removing the event listener on cleanup
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);  // Toggle the visibility of the dropdown
    };

    const selectLanguage = (lang) => {
        setCurrentLang(lang);
        localStorage.setItem('lang', JSON.stringify(lang));
        i18n.changeLanguage(lang.code.toLowerCase());
        setIsOpen(false);  // Close the dropdown after selecting a language
    };

    return (
        <div className="relative" ref={dropdownRef}>
            <button className="bg-gray-100 text-gray-600 py-2 px-4 rounded-lg flex items-center" onClick={toggleDropdown}>
                <img src={currentLang.flag} alt="Current Language" className="h-5 object-contain mr-2" />
                <span>{currentLang.code}</span>
                <svg fill="currentColor" viewBox="0 0 20 20" className="w-4 h-4 ml-2">
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                </svg>
            </button>
            {isOpen && (
                <div className="absolute bg-white shadow-lg rounded-lg mt-2 w-40">
                    {languages.map(lang => (
                        <a key={lang.code} href="#" className="block px-4 py-2 text-sm text-gray-600 hover:bg-blue-500 hover:text-white" onClick={(e) => { e.preventDefault(); selectLanguage(lang); }}>
                            {lang.name}
                            <img src={lang.flag} alt={`${lang.name} Flag`} className="h-4 inline ml-2" />
                        </a>
                    ))}
                </div>
            )}
        </div>
    );
}
